<template>
  <el-dialog
    :visible.sync="show"
    width="380px"
    top="14%"
    :close-on-click-modal="false"
    class="dialogform"
    :title="$t('dc:编辑')"
  >
    <el-form
      label-position="right"
      label-width="80px"
      :inline="false"
      ref="formData"
      :model="formData"
    >
      <el-form-item :label="$t('dc:级别2')" required>
        <el-input
          style="width: 100%"
          v-model="formData.level2"
          disabled
          :placeholder="$t('dc:请输入')"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('dc:代理商')">
        <el-select
          style="width: 100%"
          v-model="formData.agencyId"
          filterable
          :placeholder="$t('dc:请选择')"
        >
          <el-option
            v-for="(items, index) in agencyList"
            :key="index"
            :label="items.name"
            :value="items.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('dc:级别3')" required>
        <el-input
          disabled
          v-model="formData.level3"
          width="100%;"
          resize="none"
          show-word-limit
          style="width: 100%"
          :placeholder="$t('dc:请输入')"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('dc:客户')">
        <el-select
          v-model="formData.clientId"
          filterable
          :placeholder="$t('dc:请选择')"
          style="width: 100%"
        >
          <el-option
            v-for="(items, index) in clientList"
            :key="index"
            :label="items.name"
            :value="items.id"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>

    <div slot="footer">
      <el-button class="marginR20" @click="show = false">{{ $t('dc:取消') }}</el-button>
      <el-button class="btn-black" type="info" @click="submitForm('formData')">{{
        $t('dc:确认')
      }}</el-button>
    </div>
    <el-dialog
      width="25%"
      :visible.sync="innerVisible"
      append-to-body
      :close-on-click-modal="false"
      :show-close="false"
      :title="$t('dc:提醒')"
    >
      <div>{{ submitTitle }}</div>
      <div slot="footer">
        <el-button @click="innerVisible = false" class="marginR20">{{ $t('dc:否') }}</el-button>
        <el-button class="btn-black" type="info" @click="subUpdataIRIS">{{
          $t('dc:是')
        }}</el-button>
      </div>
    </el-dialog>
  </el-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'IRISeditDialog',
  components: {},
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: String,
    content: String,
    rowData: {},
  },
  watch: {
    show(v) {
      this.$emit('update:visible', v)
    },
    visible(v) {
      this.show = v
    },
  },
  data() {
    return {
      show: this.visible,
      innerVisible: false,
      formData: this.rowData,

      submitTitle: this.$t('dc:确认修改'),
      rules: {
        level2: [{ required: false }],
        agencyId: [{ required: false }],
        level3: [{ required: false }],
        clientId: [{ required: false }],
      },
    }
  },
  created() {},
  computed: {
    ...mapGetters(['agencyList', 'clientList']),
  },
  mounted() {},
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.innerVisible = true
        } else {
          return false
        }
      })
    },
    async subUpdataIRIS() {
      if (!!this.formData.agencyId)
        this.formData.agency = this.agencyList.find((j) => j.id == this.formData.agencyId).name
      if (!!this.formData.clientId)
        this.formData.client = this.clientList.find((j) => j.id == this.formData.clientId).name

      const { status } = await $Api.irismapping.updataIrisMapping(this.formData)
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.$message({
          showClose: true,
          message: this.$t('dc:成功') || 'success',
          type: 'success',
        })
        this.$emit('queryApi')
        this.innerVisible = false
        this.show = false
      }
    },
  },
}
</script>
<style lang="scss"></style>
