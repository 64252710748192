<template>
  <div class="common-page">
    <PocCardTable>
      <template slot="headerTitle">
        <span class="font21 colorB">{{ $t('dc:账号管理') }}</span>
        <span class="padRL6 font21">/</span>
        <span class="font21">{{ $t('dc:IRIS映射列表') }}</span>
      </template>
      <template slot="headerGroup">
        <div class="disFx">
          <el-select
            v-model="pageConfig.state"
            clearable
            :placeholder="$t('dc:请选择审核状态')"
            style="width: 200px; margin-right: 16px"
            @change="queryApi"
          >
            <el-option
              v-for="(items, index) in auditStatusList"
              :key="index"
              :label="items.label"
              :value="items.id"
            ></el-option>
          </el-select>
          <el-input
            v-model="pageConfig.searchKey"
            style="width: 240px"
            :placeholder="$t('dc:请输入')"
            :clearable="true"
            @keyup.enter.native="queryApi"
            @clear="queryApi"
          >
            <el-button
              class="btn-black"
              type="info"
              slot="append"
              @click="queryApi"
              icon="el-icon-search"
            ></el-button>
          </el-input>
        </div>
      </template>
      <PocTable
        v-loading="loading"
        :data="tableData"
        :current-page.sync="pageConfig.pageNum"
        :total="pageConfig.total"
        :border="true"
        @size-change="pageSizeChange"
        @page-current-change="pageChange"
      >
        <el-table-column
          v-for="item in columnOptions"
          :key="item.key"
          :prop="item.key"
          :label="item.name"
          :fixed="item.fixed"
          :sorter="item.sortable"
          :min-width="item.width"
          :align="item.align"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div class="other" v-if="item.key === 'state'">
              <span v-if="STATUS_ENUM.i18n[scope.row.state] == 'Pending'">{{
                $t('dc:待处理')
              }}</span>
              <span v-else-if="STATUS_ENUM.i18n[scope.row.state] == 'Normal'">{{
                $t('dc:正常')
              }}</span>
            </div>
            <div class="other" v-else>
              <span>{{ scope.row[item.key] || '-' }}</span>
            </div>
          </template>
        </el-table-column>

        <el-table-column :label="$t('dc:操作')" width="80" fixed="right" align="center">
          <template slot-scope="scope">
            <el-tooltip effect="dark" :content="$t('dc:编辑')" placement="top">
              <i
                class="poc-icon-info poc-size12 iconfont icon-oneui-contract-edit padding12 nicon"
                @click="IRISMappingEdit(scope.row)"
              ></i>
            </el-tooltip>
          </template>
        </el-table-column>
      </PocTable>
    </PocCardTable>
    <IRISMappingEditDialog
      v-if="showEditStatus"
      :rowData="rowData"
      :visible.sync="showEditStatus"
      @queryApi="queryApi"
    ></IRISMappingEditDialog>
  </div>
</template>
<script>
import IRISMappingEditDialog from '@/views/dialog/IRISMappingEditDialog'
export default {
  name: 'IRISMappingList',
  mixins: [$PCommon.TableMixin],
  components: {
    IRISMappingEditDialog,
  },
  data() {
    return {
      loading: false,
      tableData: [],
      pageConfig: {
        state: '',
      },
      rowData: {},
      showEditStatus: false,
      auditStatusList: [
        {
          label: 'Normal',
          id: 0,
        },
        {
          label: 'Pending',
          id: 1,
        },
      ],
    }
  },
  computed: {
    STATUS_ENUM() {
      return {
        i18n: {
          Normal: this.$t('dc:正常'),
          Pending: this.$t('dc:待处理'),
          0: 'Normal',
          1: 'Pending',
        },
      }
    },
    columnConfig() {
      return [
        {
          key: 'level2',
          name: this.$t('dc:级别2'),
          dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
          visible: true, // 是否展示在表格列中
          sortable: false, // 是否可以排序
          fixed: false, // 固定列的位置(left, right)
          width: null, // 默认宽度，像素
          align: 'left',
        },
        {
          key: 'agency',
          name: this.$t('dc:代理商'),
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: null,
          width: 100,
          align: 'left',
        },
        {
          key: 'level3',
          name: this.$t('dc:级别3'),
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: null,
          width: 100,
          align: 'left',
        },
        {
          key: 'client',
          name: this.$t('dc:客户'),
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: null,
          width: 100,
          align: 'left',
        },
        {
          key: 'authorName',
          name: this.$t('dc:批准人'),
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: null,
          width: null,
          align: 'left',
        },
        {
          key: 'mTime',
          name: this.$t('dc:更新时间'),
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: null,
          width: 120,
          align: 'left',
        },
        {
          key: 'state',
          name: this.$t('dc:审核状态'),
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: null,
          width: null,
        },
      ]
    },
    columnOptions() {
      return this.columnConfig.filter((n) => n.visible)
    },
  },
  mounted() {
    this.queryApi()
  },
  methods: {
    async queryApi() {
      this.loading = true
      const { status, data } = await $Api.irismapping.irisMappinglist(this.pageConfig)
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.pageConfig.total = data.total
        this.tableData = data.list
      }
      this.loading = false
    },
    IRISMappingEdit(row) {
      this.rowData = row
      this.showEditStatus = true
    },
  },
}
</script>
<style>
.disFx {
  display: flex;
}
[class*=' poc-icon-'].disabled {
  cursor: not-allowed !important;
}
</style>
